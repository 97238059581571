import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import L from "leaflet";
import { Marker, useMap } from "react-leaflet";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { promiseToFlyTo, getCurrentLocation } from "lib/map";

import Layout from "components/Layout";
import Container from "components/Container";
import Map from "components/Map";
import VenueList from "components/VenueList";
import Snippet from "components/Snippet";

import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { DateTime } from "luxon";

const LOCATION = {
  lat: 30.2828398,
  lng: -87.6246071,
};

const LOCATION_TEST = [30.2828398, -87.6846071];
const gt = [30.2828398, -87.7846071];

const CENTER = [LOCATION.lat, LOCATION.lng];
const DEFAULT_ZOOM = 10;
const ZOOM = 13;

const timeToZoom = 5000;
const timeToOpenPopupAfterZoom = 4000;
const timeToUpdatePopupAfterZoom = timeToOpenPopupAfterZoom + 3000;

const SCMapHolder = styled.div`
  &.map-shrink {
    flex: 1;
  }

  flex: 4;
  .map {
    height: 40vh;
  }
`;

const SCControls = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    height: 100%;
    min-width: 60px;
  }

  select {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5rem;
  }
`;

const SCListHolder = styled.div`
  flex: 6;
`;

/**
 * MapEffect
 * @description This is an example of creating an effect used to zoom in and set a popup on load
 */

const MapEffect = ({ markerRef }) => {
  const map = useMap();
  useEffect(() => {
    console.log("1");
    if (!markerRef.current || !map) return;

    (async function run() {
      const popup = L.popup({
        maxWidth: 800,
      });

      const location = await getCurrentLocation().catch(() => LOCATION);

      const { current: marker } = markerRef || {};

      marker.setLatLng(location);
      popup.setLatLng(location);

      setTimeout(async () => {
        await promiseToFlyTo(map, {
          zoom: ZOOM,
          center: location,
        });

        marker.bindPopup(popup);

        setTimeout(() => marker.openPopup(), timeToOpenPopupAfterZoom);
        // setTimeout(
        //   () => marker.setPopupContent(popupContentGatsby),
        //   timeToUpdatePopupAfterZoom
        // );
      }, timeToZoom);
    })();
  }, [map, markerRef]);

  return null;
};

MapEffect.propTypes = {
  markerRef: PropTypes.object,
};

const VenuePage = () => {
  const markerRef = useRef([]);
  const myloc = useRef();
  const mapRef = useRef();

  const [activeVenue, setActiveVenue] = useState(null);

  const mapSettings = {
    center: CENTER,
    defaultBaseMap: "OpenStreetMap",
    zoom: DEFAULT_ZOOM,
  };

  useEffect(() => {
    (async () => {
      try {
        const tmp =
          (await getCurrentLocation().catch(() => console.log("err"))) || "";

        if (myloc) myloc.current.setLatLng(tmp);
      } catch (ex) {}
    })();
  }, []);

  return (
    <Layout pageName="venues">
      <Helmet>
        <title>Venues</title>
      </Helmet>
      <SCMapHolder ref={mapRef}>
        <Map {...mapSettings}>
          <StaticQuery
            query={graphql`
              {
                allVenue {
                  edges {
                    node {
                      id
                      zipCode
                      state
                      longitude
                      latitude
                      city
                      address
                      venueName
                      phoneNumber
                    }
                  }
                }
              }
            `}
            render={(data) =>
              data.allVenue.edges.map((node, idx) => (
                <Marker
                  eventHandlers={{
                    click: (e) => {
                      setActiveVenue(node.node);
                    },
                  }}
                  key={`marker-${idx}`}
                  position={[node.node.latitude, node.node.longitude]}
                ></Marker>
              ))
            }
          />
          <Marker ref={myloc} position={gt}></Marker>
        </Map>
      </SCMapHolder>

      <SCListHolder>
        <h1>Venues</h1>
        <VenueList setActiveVenue={setActiveVenue}></VenueList>
      </SCListHolder>
    </Layout>
  );
};

export default VenuePage;
