import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { StaticQuery, graphql, Link } from "gatsby";
import { slugger } from "../utils/string";

export default function VenueList({ setActiveVenue }) {
  return (
    <ListGroup>
      <StaticQuery
        query={graphql`
          {
            allVenue {
              edges {
                node {
                  id
                  zipCode
                  state
                  longitude
                  latitude
                  city
                  address
                  venueName
                }
              }
            }
          }
        `}
        render={(data) =>
          data.allVenue.edges.map((node, idx) => (
            <ListGroupItem>
              <Link
                to={`/venue/${slugger(node.node.venueName)}`}
                key={`LIST-${idx}`}
              >
                {node.node.venueName}
              </Link>
            </ListGroupItem>
          ))
        }
      />
    </ListGroup>
  );
}
